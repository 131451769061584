import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Image from "next/image";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  errorText: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  errorMessage: {
    fontWeight: "normal",
  },
  img: {
    marginBottom: theme.spacing(2),
  },
  content: {
    textAlign: "center",
  },
}));

interface StatusProps {
  code: number;
  message?: string;
}

export const Status = ({ code, message = "Whoops!" }: StatusProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {code === 404 && (
          <Image className={classes.img} src="/img/serenity.gif" alt="Serenity now!" width="480" height="360" />
        )}
        {code === 5000 && (
          <Image className={classes.img} src="/img/reservation.jpg" alt="We made an error" width="580" height="360" />
        )}

        <Box className={classes.errorText}>
          <Typography variant="h3" className={classes.errorMessage}>
            Error code: {code}
          </Typography>
          <Typography variant="h5" className={classes.errorMessage}>
            {message}
          </Typography>
        </Box>
      </main>
    </div>
  );
};

export default Status;
